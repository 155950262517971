import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useDebouncedCallback } from 'use-debounce';

import {
  GAQuoteFlowClickHowWillWeUseThis,
  GAQuoteFlowSubmitNameAndEmail,
} from '@pumpkincare/analytics';
import { MC_URL } from '@pumpkincare/config';
import {
  getDiscountId,
  useDiscount,
  validateDiscountByZipCode,
} from '@pumpkincare/discounts';
import { setMarketingAttributionProperty } from '@pumpkincare/marketing';
import { validateEmail } from '@pumpkincare/quotes';
import {
  captureException,
  EMBARK,
  ERROR,
  GENERIC_ERROR,
  requestZipCodeValidity,
  useBanners,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  LegalBody,
  TextField,
  Typography,
  ZipcodeField,
} from '@pumpkincare/shared/ui';
import { postCheckEmail } from '@pumpkincare/user';
import { VetAttribution } from '@pumpkincare/vets/ui';

import {
  getQuoteFlowNextStep,
  getQuotesEmail,
  getQuotesFirstName,
  getQuotesLastName,
  getQuotesPartner,
  getQuotesPolicyZipCode,
  getQuotesVetAttributionData,
} from '../../selectors';
import {
  clearSubmittingQuote,
  setQuotesPolicyZipCode,
  setRegisterQuoteStatus,
} from '../../state';
import registerQuote from '../../thunks/register-quote';
import submitRegister from '../../thunks/submit-register';
import updateQuoteVetAttribution from '../../thunks/update-quote-vet-attribution';
import QuoteFlowHeader from '../quote-flow-header';
import { NextButton, QuoteFlowComponentContent } from '../shared';

import styles from './register.module.css';

// TODO: Known BE issue, to be prioritized post MGA launch
const discountBandaidStates = {
  HI: ['vet', 'employer'],
  MN: ['vet'],
  TN: ['employer'],
  NY: ['vet'],
  Fl: ['employer'],
};

function Register() {
  const dispatch = useDispatch();
  const quotesPolicyZipCode = useSelector(getQuotesPolicyZipCode);
  const quotesEmail = useSelector(getQuotesEmail);
  const quotesFirstName = useSelector(getQuotesFirstName);
  const quotesLastName = useSelector(getQuotesLastName);
  const quotesVetAttributionData = useSelector(getQuotesVetAttributionData);

  const { addBanner, removeAllBanners } = useBanners();

  const [firstName, setFirstName] = useState(quotesFirstName || '');
  const [lastName, setLastName] = useState(quotesLastName || '');
  const [email, setEmail] = useState(quotesEmail || '');
  const [zipCode, setZipCode] = useState(quotesPolicyZipCode || '');
  const [policyState, setPolicyState] = useState(null);

  const [showHowWeUseInfo, setShowHowWeUseInfo] = useState(false);
  const [isZipCodeError, setZipCodeError] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitting, toggleIsSubmitting] = useBooleanInput(false);

  const isValidEmail = validateEmail(email);
  const isEmailInputValid = !!email && isValidEmail && !emailError;
  const isZipCodeInputValid = !!zipCode && isZipCodeError === false;

  const vet = useRef(quotesVetAttributionData);
  const [canSubmitVet, setCanSubmitVet] = useState(false);
  const [zipDisabled, setZipDisabled] = useState(false);
  const quotePartner = useSelector(getQuotesPartner);
  const nextRoute = useSelector(getQuoteFlowNextStep);
  const shouldSubmitDiscount = useRef(true);

  const canSubmit =
    firstName &&
    lastName &&
    isEmailInputValid &&
    isZipCodeInputValid &&
    isSubmitting === false &&
    canSubmitVet;

  const invalidZipError =
    'Hmmm, we can’t find that one. Please enter a valid U.S. zip code.';
  const ineligibleDiscount =
    'Unfortunately, due to state regulations, you are not eligible for a group discount. Don’t worry you are still eligible for coverage.';

  const discountQuery = useDiscount();
  const discountId = getDiscountId(discountQuery.data);

  const debouncedValidateEmail = useDebouncedCallback(value => {
    postCheckEmail(value)
      .then(({ exists }) => {
        setEmailError(
          exists ? (
            <span className={styles.logIn}>
              Looks like you already have a Pumpkin account. Please{' '}
              <a href={MC_URL}>log in</a> and visit the "My Pet's Plan" page to add
              another pet.
            </span>
          ) : (
            ''
          )
        );
      })
      .catch(() => {
        setEmailError('Unable to verify email');
      });
  }, 500);

  const debouncedValidateZipCode = useDebouncedCallback(value => {
    requestZipCodeValidity(value)
      .then(({ state, valid }) => {
        setZipCodeError(false);
        setPolicyState(state);
        dispatch(setQuotesPolicyZipCode(value));

        if (!valid && !state) {
          setErrorMessages(state => ({ ...state, zipcode: invalidZipError }));
        } else if (discountId) {
          validateDiscountByZipCode(discountId, value)
            .then(discount => {
              if (
                discountBandaidStates[state]?.find(
                  override => override === discount.type
                )
              ) {
                setErrorMessages(state => ({
                  ...state,
                  zipcode: ineligibleDiscount,
                }));
                shouldSubmitDiscount.current = false;
              } else {
                shouldSubmitDiscount.current = true;
              }
            })
            .catch(error => {
              switch (error.response.status) {
                case 400: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: invalidZipError,
                  }));
                  break;
                }
                case 404: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: 'Discount not found.',
                  }));

                  break;
                }
                case 422: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: ineligibleDiscount,
                  }));

                  break;
                }
                default: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: 'Generic error.',
                  }));

                  break;
                }
              }
            });
        } else {
          setErrorMessages(state => ({ ...state, zipcode: null }));
        }
      })
      .catch(() => {
        setZipCodeError(true);
        setPolicyState(null);
        setErrorMessages(state => ({ ...state, zipcode: invalidZipError }));
      });
  }, 500);

  function handleVetClinicChange(data) {
    vet.current = data;

    setCanSubmitVet(!!vet.current?.vet_name);
  }

  function handleHasVetChange(value) {
    vet.current = {};
    setCanSubmitVet(value ? !!vet.current?.vet_name : true);
  }

  function handleFirstNameChange({ target: { value } }) {
    setFirstName(value);
  }

  function handleLastNameChange({ target: { value } }) {
    setLastName(value);
  }

  function handleEmailChange({ target: { value } }) {
    setEmailError('');
    setEmail(value);

    if (validateEmail(value)) {
      debouncedValidateEmail(value);
    }
  }

  function handleZipCodeChange({ target: { value } }) {
    setZipCodeError(null);
    setZipCode(value);

    if (value.length === 5) {
      debouncedValidateZipCode(value);
    }
  }

  function handleNextClick() {
    GAQuoteFlowSubmitNameAndEmail();

    removeAllBanners();
    toggleIsSubmitting();

    dispatch(clearSubmittingQuote());
    dispatch(
      submitRegister({
        email: email.toLowerCase(),
        firstName,
        lastName,
        zipCode,
        policyState,
      })
    );
    setZipDisabled(true);
    dispatch(updateQuoteVetAttribution(vet.current || {}));
    dispatch(registerQuote(shouldSubmitDiscount.current ? discountId : ''))
      .then(() => {
        setMarketingAttributionProperty({ isGoingToPlanSelection: true });

        dispatch(push(nextRoute));
      })
      .catch(err => {
        captureException(err);

        toggleIsSubmitting();
        addBanner(GENERIC_ERROR);

        dispatch(clearSubmittingQuote());
        dispatch(setRegisterQuoteStatus(ERROR));
      });
  }

  function handleShowHowWeUseInfo(e) {
    e.preventDefault();

    GAQuoteFlowClickHowWillWeUseThis();

    setShowHowWeUseInfo(!showHowWeUseInfo);
  }

  return (
    <>
      <QuoteFlowHeader className={styles.headerTitle}>
        {quotePartner === EMBARK ? (
          <>
            First, let’s confirm <br /> your info.
          </>
        ) : (
          'One last thing! Let’s get your info, human.'
        )}
      </QuoteFlowHeader>

      <QuoteFlowComponentContent className={styles.register}>
        <LegalBody className={styles.headerText}>
          This info can’t be edited at checkout, so make sure you enter it
          prrrfectly!
        </LegalBody>

        <TextField
          label={'Your First Name'}
          id={'first-name'}
          onChange={handleFirstNameChange}
          value={firstName}
          data-testid={'first-name-input'}
          classes={{ container: styles.textField }}
          required
          autoFocus
        />

        <TextField
          label={'Your Last Name'}
          id={'last-name'}
          aria-label={'Your Last Name'}
          onChange={handleLastNameChange}
          value={lastName}
          data-testid={'last-name-input'}
          classes={{ container: styles.textField }}
          required
        />

        <TextField
          label={'Email Address'}
          id={'email'}
          onChange={handleEmailChange}
          error={{
            hasError: !(isEmailInputValid || email === ''),
            errorMessage: emailError,
          }}
          value={email}
          data-testid={'email-input'}
          classes={{ container: styles.textField }}
          required
        />

        <ZipcodeField
          label={'Home Zip Code'}
          id={'zipCode'}
          placeholder={'Home Zip Code'}
          onChange={handleZipCodeChange}
          readOnly={zipDisabled}
          value={zipCode}
          error={{
            hasError: !(isZipCodeInputValid || zipCode === ''),
          }}
          classes={{ container: styles.textField }}
          data-testid={'zipcode-input'}
          required
        />

        {Object.values(errorMessages).map(errMsg => {
          return errMsg ? (
            <p
              key={errMsg}
              className={classNames(Typography.legalBody, styles.error)}
            >
              {errMsg}
            </p>
          ) : null;
        })}

        <VetAttribution
          disabled={!isZipCodeInputValid}
          zipCode={zipCode}
          selectedVet={vet.current}
          onVetClinicChange={handleVetClinicChange}
          onHasVetChange={handleHasVetChange}
        />

        <div className={styles.howUseWrapper}>
          <span className={styles.howUse}>
            <img alt='info' src='/assets/images/info.svg' />

            <span
              onClick={handleShowHowWeUseInfo}
              data-event='Click How will we use this?'
              data-category='Quote Flow'
              className={classNames(Typography.legalBody, styles.howUseLink)}
            >
              How will we use this?
            </span>
          </span>

          {showHowWeUseInfo ? (
            <p className={classNames(Typography.legalBody, styles.howUseBlurb)}>
              Sharing your contact information allows us to provide you with a quote
              based on your location. It also allows us to retrieve a past quote for
              you if you decide you want to purchase at a later date.
            </p>
          ) : null}
        </div>
      </QuoteFlowComponentContent>

      <NextButton
        disabled={!canSubmit}
        onClick={handleNextClick}
        isLoading={isSubmitting}
        style={{ marginTop: '24px' }}
      />
    </>
  );
}

export default Register;
